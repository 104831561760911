<template>
  <div class="container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ title }}
      </template>
    </Breadcrumb>
    <h2 class="text-success mb-3">{{ title }}</h2>
    <hr />
    <div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          :hide-on-single-page="true"
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <div v-if="type === 'sats'">
        <el-table
          :data="results"
          border
          style="width: 100%;margin-bottom:30px"
          class="tests-list"
        >
          <el-table-column label="Student">
            <template slot-scope="scope">
              {{ scope.row.first_name }} {{ scope.row.last_name }}
            </template>
          </el-table-column>
          <el-table-column label="Date">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column label="English">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.section_english_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Math">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.section_math_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Score">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.total_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Details">
            <template slot-scope="scope">
              <a
                :href="'/sat/result?user_exam_id=' + scope.row.user_exam_id"
                target="_blank"
              >
                <i class="fa fa-eye"></i>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="type === 'acts'">
        <el-table
          :data="results"
          border
          style="width: 100%;margin-bottom:30px"
          class="tests-list"
        >
          <el-table-column label="Student">
            <template slot-scope="scope">
              {{ scope.row.first_name }} {{ scope.row.last_name }}
            </template>
          </el-table-column>
          <el-table-column label="Date">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column label="English">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.english_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Math">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.math_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Reading">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.reading_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Science">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.science_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Score">
            <template slot-scope="scope">
              <b class="text-success">{{ scope.row.total_score }}</b>
            </template>
          </el-table-column>
          <el-table-column label="Details">
            <template slot-scope="scope">
              <a
                :href="'/act/result?user_exam_id=' + scope.row.user_exam_id"
                target="_blank"
              >
                <i class="fa fa-eye"></i>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          :hide-on-single-page="true"
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import Tests from "@/apis/tests.js";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: this.title + " - Ivy-Way Academy"
    };
  },

  components: { Breadcrumb },

  props: [],
  data() {
    return {
      title: "",
      results: [],
      resultsTotal: 0,
      pageSize: 0
    };
  },
  computed: {
    instant() {
      return instant;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    examId() {
      let exam_id = 1;
      if (this.$route.query.exam_id) {
        exam_id = this.$route.query.exam_id;
      }
      return parseInt(exam_id);
    },
    type() {
      let type = "sats";
      if (this.$route.query.type) {
        if (this.$route.query.type === "sat") {
          type = "sats";
        } else if (this.$route.query.type === "act") {
          type = "acts";
        }
      }
      return type;
    }
  },
  watch: {},

  mounted() {
    this.getResults();
  },

  methods: {
    setPage(page) {
      this.routerPush({
        name: "TestsList",
        query: {
          type: this.$route.query.type,
          exam_id: this.examId,
          page: page
        }
      });
    },
    async getResults() {
      const results = await Tests.getScoreList(this.type, this.examId, {
        page: this.page
      });
      const res = results[this.$route.query.type + "_scores"];
      this.results = res.data;
      this.resultsTotal = res.total;
      this.pageSize = res.per_page;
      this.title = results.exam.full_name;
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
</style>
